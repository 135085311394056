import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Projects`}</h1>
    <ProjectCard title="Pronto" link="https://devpost.com/software/pronto-bjrm1n" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  This project was created for a hackathon. Pronto is a credit-based
  subscription service to your favourite restaurants.
    </ProjectCard>
    <ProjectCard title="Hocus Focus" link="https://devpost.com/software/hocus-focus-64xe8c" bg="linear-gradient(to right, rgb(102, 45, 140) 0%, rgb(237, 30, 121) 100%)" mdxType="ProjectCard">
  Hackathon winner. This project uses eye-tracking to help users stay focused.
    </ProjectCard>
    <ProjectCard title="Klar" link="https://devpost.com/software/klar" bg="linear-gradient(to right, rgb(0, 146, 69) 0%, rgb(252, 238, 33) 100%)" mdxType="ProjectCard">
  Hackathon winner. This project uses ML and Computer Vision to help
  photographers select their best pictures.
    </ProjectCard>
    <ProjectCard title="CalmYourSO" link="https://sameerpujji.github.io/CalmYourSO/" bg="linear-gradient(to right, rgb(213, 133, 255) 0%, rgb(0, 255, 238) 100%)" mdxType="ProjectCard">
  A project aimed at reducing fights between couples.
    </ProjectCard>
    <ProjectCard title="Dining Bot" link="https://github.com/SameerPujji/DiningHallMenu" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  A javascript scraper which texts users the dining hall menu for the day
    </ProjectCard>
    <ProjectCard title="Valet Me" link="" bg="linear-gradient(to right, rgb(102, 45, 140) 0%, rgb(237, 30, 121) 100%)" mdxType="ProjectCard">
  A web and mobile app aimed at making valet parking more efficient.
    </ProjectCard>
    <ProjectCard title="Special Project" link="https://www.youtube.com/watch?v=dQw4w9WgXcQ" bg="linear-gradient(to right, rgb(0, 146, 69) 0%, rgb(252, 238, 33) 100%)" mdxType="ProjectCard">
  Click this box to see a special project
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      