import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Contact`}</h2>
    <p>{`Please feel free to reach out to me via `}<a href="mailto:spujji@uwaterloo.ca">{` email`}</a>{`. You can also check out my `}<a href="https://www.linkedin.com/in/sameerpujji">{` LinkedIn`}</a>{`, `}<a href="https://www.github.com/sameerpujji">{` GitHub`}</a>{`, and `}<a href="https://www.devpost.com/sameerpujji">{` DevPost`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      