import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`Hi, I’m Sameer. I am a double degree student at the University of Waterloo (Honours CS) and Wilfrid Laurier University (Honours BBA). I have proven experience in strategy planning, finance, and technology, which has led to higher business performance, customer satisfcation, and sales. I am business-orientated and an excellent executor and am looking for an opportunity to further develop my knowledge and skills in a challenging environment. I am excited to be pursuing a career in financial services and technology. Thank you for checking out my website. `}<a href="https://drive.google.com/file/d/1Sl6AIybzjiu-yMfYbuFmWAJMM9g_jyqa/view?usp=sharing">{`You can find my resume here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      